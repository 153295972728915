import React, { useMemo } from 'react';
import styled from 'styled-components';

import Flex from '../../common/Flex';
import ComplexCellRenderer from '../ComplexCellRenderer';
import Text from '../../common/Text';
import Button from '../../common/Button';

import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import ListCreatorEditor from '../ListCreatorEditor';

import { createSelector } from 'reselect';
import { selectParametersOptions } from '../../../entities/Standardize/Parameters/selectors';
import { useSelector } from 'react-redux';

const NoteContentsListContainer = styled.div`
  margin-left: 2%;
  float: right;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;

  min-height: 2.5rem;
  max-height: 21.5rem;
  height: 11.5rem;

  min-width: 10rem;
  width: 48%;
  overflow-y: scroll;
`;

const NoContentsBase = styled(Text)`

  border: 1px solid #ced4da;

  padding: 1rem;
`;

const mapStateToProps = (state) => createSelector(
  selectParametersOptions(),
  (parameters) => ({ parameters })
);

const NoContents = () => <NoContentsBase>No Note Contents</NoContentsBase>;

const DeleteNCButton = ({noteContent, deleteNoteContent}) => <Button icon='delete' iconFillColor='error.4' style={{ float: 'left' }} onClick={() => deleteNoteContent(noteContent.id)} />;

const NoteContentEditor = ({noteContent, currentNoteContent, onEditNoteContent, onFocusNoteContent, onBlurNoteContent}) => {
  const OriginalNoteContent = useMemo(() => noteContent, []);

  const value = useMemo(() => {
    if(noteContent.contentType == 'text') {
      return currentNoteContent?.id == noteContent.id ? currentNoteContent.value : noteContent.value;
    }

    return currentNoteContent?.id == noteContent.id ? currentNoteContent.defaultValue : noteContent.defaultValue;
  }, [currentNoteContent]);

  const hoverNoteContent = (ncid) => {
    const ncSpan = document.querySelector(`span.ncid${ncid}`);
    if (ncSpan) {
      ncSpan.classList.add('hovered-note-content');
    }
  };

  const unhoverNoteContent = (ncid) => {
    const ncSpan = document.querySelector(`span.ncid${ncid}`);
    if (ncSpan) {
      ncSpan.classList.remove('hovered-note-content');
    }
  };

  switch (noteContent.contentType) {
  case 'date':
    return <InputText className='note-content-input' id={noteContent.id} value={value} onFocus={() => onEditNoteContent(noteContent.id)} onBlur={() => onBlurNoteContent(noteContent.id)} />;
  case 'list':
    return <InputText className='note-content-input' id={noteContent.id} value={value} onFocus={() => onEditNoteContent(noteContent.id)} onBlur={() => onBlurNoteContent(noteContent.id)} />;
  case 'text':
    return <InputText
      className='note-content-input'
      id={noteContent.id}
      value={value}

      onChange={(e) => onEditNoteContent(e.target.value)}
      onFocus={() => onFocusNoteContent(noteContent)}
      onBlur={() => onBlurNoteContent(noteContent.id)}

      onMouseEnter={() => { hoverNoteContent(noteContent.id); }}
      onMouseLeave={() => { unhoverNoteContent(noteContent.id); }}
    />;
  case 'number':
    return <InputNumber className='note-content-input' id={noteContent.id} value={value} onFocus={() => onEditNoteContent(noteContent)} onBlur={() => onBlurNoteContent(noteContent.id)} />;
  case 'parameter':
    return <Dropdown
      value={value}
      options={parameters}
      filter={true}

      optionLabel='label'
      optionValue='value'

      onChange={(e) => handleEditedRowChange(e.value, 'defaultValue', currentEditingRow)}
      style={{ width: '100%' }}

      onFocus={() => onEditNoteContent(noteContent.id)} onBlur={() => onBlurNoteContent(noteContent.id)}
    />;
  default:
    return <Text>No Editor Found</Text>;
  }
};

export default function NoteContentList({noteContents, currentNoteContent, onEditNoteContent, onFocusNoteContent, onBlurNoteContent, deleteNoteContent}) {
  const {parameters} = useSelector(mapStateToProps);

  return (
    <NoteContentsListContainer >
      {
        noteContents?.length && noteContents.map((noteContent, index) => {
          return <Flex key={index} flexDirection='row' justifyContent='center' alignItems='center' >
            <DeleteNCButton noteContent={noteContent} deleteNoteContent={() => deleteNoteContent(noteContent.id)} />
            <NoteContentEditor
              noteContent={noteContent}
              currentNoteContent={currentNoteContent}
              onEditNoteContent={onEditNoteContent}
              onFocusNoteContent={onFocusNoteContent}
              onBlurNoteContent={onBlurNoteContent}
              parameters={parameters}
            />
          </Flex>;
        }) || <NoContents />
      }
    </NoteContentsListContainer>
  );
}