import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '../../common/Box';
import Button from '../../common/Button';
import Flex from '../../common/Flex';
import NoteContentList from './NoteContentList';
import { NoteBodyEditor } from './NoteBodyEditor';

import './styles.css';

const testData = [
  {
    id: '1',
    defaultValue: 'test',
    contentType: 'text',
    value: 'test'
  },
  {
    id: '2',
    defaultValue: 'test2',
    contentType: 'text',
    value: 'test2'
  },
  {
    id: '3',
    defaultValue: 'test3',
    contentType: 'text',
    value: 'test3'
  },
  {
    id: '4',
    defaultValue: 'test4',
    contentType: 'text',
    value: 'test4'
  },
];

const testNoteBody = 'this is a test ${1} body. This \'${2}\' is a note content. ${3} ${4}.';

export default function MultiValueNoteBodyEditor({ note, createNoteContent, deleteNoteContent }) {

  const [noteContents, setNoteContents] = useState(testData);//note.noteContents);
  const [noteBody, setNoteBody] = useState(testNoteBody);//note.noteBody);
  const [currentNoteContent, setCurrentNoteContent] = useState(null);

  const [elements, setElements] = useState([]);

  const CERef = useRef();

  const replaceBetweenSpanTags = (html, newValue) => {
    return html.replace(/<span\b[^>]*>(.*?)<\/span>/g, (match, p1) => {
      return newValue;
    });
  };

  const replaceSpanTags = (parent) => {
    parent.querySelectorAll('span').forEach(span => {
      const ncId = span.id;
      const nc = noteContents.find(nc => nc.id == ncId);
      const ncVal = `\${${ncId}}`;
      span.outerHTML = ncVal;
    });
  };

  const onCreateNoteContent = () => {
    const selection = document.getSelection().getRangeAt(0);
    const newNCSpan = document.createElement('span');
    newNCSpan.id = 'fakeId';
    newNCSpan.className = 'note-content-tag';
    newNCSpan.innerHTML = 'new note content';
    newNCSpan.contentEditable = false;
    selection.insertNode(newNCSpan);
    // replaceSpanTags(document.getElementById('editable-div-container'));
    // setNoteContents(_noteContents => {
    //   return [..._noteContents, { id: 'fakeId', defaultValue: 'fakeId', contentType: 'text' }];
    // });
    // setNoteBody(document.getElementById('editable-div-container').innerHTML);

    // CERef.current.updateOrder(document.getElementById('editable-div-container').innerHTML, [...noteContents, { id: 'fakeId', defaultValue: 'fakeId', contentType: 'text' }]);
  };

  const onEditNoteContent = (newVal, field='') => {
    //edits the value field by default, but can edit any field
    setCurrentNoteContent(_currentNoteContent => {
      if(_currentNoteContent.contentType == 'list') {
        let _nc = { ..._currentNoteContent };
        if (field) {
          _nc[field] = newVal;
        }
        _nc.value = newVal;
        return _nc;
      }

      return { ..._currentNoteContent, value: newVal };
    });

  };

  const onFocusNoteContent = (noteContent) => {
    setCurrentNoteContent(noteContent);

    // const evt = new Event('mouseover');
    // document.querySelectorAll(`span.ncid${noteContent.id}`)[0].dispatchEvent(evt);

    const ncSpan = document.querySelector(`span.ncid${noteContent.id}`);
    if (ncSpan) {
      ncSpan.classList.add('current-note-content');
    }
  };

  const onBlurNoteContent = (ncid) => {

    // const evt = new Event('mouseout');
    // document.querySelector(`span.ncid${ncid}`).dispatchEvent(evt);

    const ncSpan = document.querySelector(`span.ncid${ncid}`);
    if (ncSpan) {
      ncSpan.classList.remove('current-note-content');
      ncSpan.innerHTML = currentNoteContent.value;
    }

    setNoteContents(_noteContents => {
      return _noteContents.map(nc => {
        if (nc.id == currentNoteContent.id) {
          return { ...nc, value: currentNoteContent.value, defaultValue: currentNoteContent.value };
        }
        return nc;
      });
    });
    setCurrentNoteContent(null);
  };

  const handleDeleteNoteContent = (ncid) => {
    setElements(_elements => {
      let _e = [..._elements];
      const indexToRemove = _elements.findIndex(item => typeof(item) != 'string' && item.id == ncid);
      _e.splice(indexToRemove, 1);
      return _e;
    });
    deleteNoteContent(ncid);
  };

  // useEffect(() => {
  //   setNoteBody(note.noteBody);
  //   setNoteContents(note.noteContents);
  // }, [note]);

  return (
    <>
      <Box style={{ width: '80%' }} ><Button icon='add' style={{ float: 'left' }} onClick={onCreateNoteContent} /></Box>
      <Flex flexDirection="row" style={{width: '100%'}}>
        <NoteBodyEditor
          value={noteBody} noteContents={noteContents}
          ref={CERef} setNoteContents={setNoteContents} elements={elements} setElements={setElements}
        />
        <NoteContentList noteContents={noteContents} currentNoteContent={currentNoteContent} onFocusNoteContent={onFocusNoteContent} onBlurNoteContent={onBlurNoteContent} onEditNoteContent={onEditNoteContent} deleteNoteContent={deleteNoteContent} />
      </Flex>
    </>
  );
}
