import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';
import EK from '../../keys';

import NoteContentSchema from './schema';
import { denormalize } from 'normalizr';

const selectNormalizedNoteContents = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.NOTE_CONTENTS.state)
);

export const selectCurrentNoteContents = (noteId) => createSelector(
  selectEntities(),
  selectNormalizedNoteContents(),
  (entities, noteContents) => noteContents && denormalize(noteContents.filter( nc => nc.note?.id === noteId ), [NoteContentSchema], entities)?.toList().toArray() || []
);
