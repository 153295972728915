import React, { useEffect, useState, useRef, useMemo, Component } from 'react';
import styled from 'styled-components';
import Button from '../../common/Button';
import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom';
import { flushSync } from 'react-dom';
import { getIn } from 'immutable';

// border: 1px solid #ccc;
// border-radius: 4px;
// padding: 8px;
// min-height: 100px;
// overflow: auto;
// white-space: wrap;
// width: 75%;
// min-width: 25px;
const EditableDiv = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;

  min-height: 2.5rem;
  max-height: 21.5rem;

  min-width: 10rem;
  width: 50%;

  line-height: 2;
`;

export const NoteContentTag = ({ noteContent, index }) =>
  <span
    contentEditable={false}
    className={'note-content-tag' + ' ncid' + noteContent.id}
    id={noteContent.id}
  >
    {noteContent.defaultValue && `${noteContent.defaultValue}` || noteContent.value || '      '}
  </span>;

export class NoteBodyEditor extends Component{

  // getInitialState () {
  //   const { value, noteContents, updateOrder, onChange, hoveredNCID, elements, setElements, deleteNoteContent, contentEditableRef } = this.props;

  //   return {
  //     elements: elements,
  //     setElements: setElements,
  //     contentEditableRef: contentEditableRef,
  //     onChange: onChange,
  //     hoveredNCID: hoveredNCID,
  //     value: value,
  //     noteContents: noteContents,
  //     updateOrder: updateOrder,
  //     deleteNoteContent: deleteNoteContent,
  //   };
  // }

  updateOrder = (newValue=false, newNoteContents=[]) => {
    if (!newValue && !this.props.value) {
      return;
    }

    const noteContents = newNoteContents.length ? newNoteContents : this.props.noteContents;

    const newOrder = []; // an array of note contents that is used to update the indexed list of note contents to the right of the note body editor
    const _elements = []; // an array of all components in the note body
    const valArr = newValue && newValue.split('${') || this.props.value.split('${');

    valArr.forEach(item => {
      if (item.includes('}')) {
        const [id, rest] = item.split('}');
        const ncVal = noteContents?.find(nc => nc.id == id) || `\${${id}}`;
        newOrder.push(ncVal);
        _elements.push(ncVal);
        if (rest) {
          _elements.push(rest);
        }
      } else {
        _elements.push(item);
      }
    });
    this.props.setElements(_elements);
    this.props.setNoteContents(newOrder);

    return _elements;
  }

  reRender = () => {
    this.forceUpdate();
  }

  componentDidUpdate (prevProps) {
    // if (prevProps.value != this.props.value) {
    //   this.updateOrder();
    // }
  }

  componentDidMount () {
    this.updateOrder();
  }

  render () {

    return <EditableDiv role='textbox' id='editable-div-container' contentEditable suppressContentEditableWarning={true} onBlur={() =>console.log('blurred')} onInput={(e) => this.props.onChange(e.currentTarget.innerHTML)} >
      {
        this.props.elements.map((element, i) => {
          if (typeof (element) == 'string') {
            return element;
          }
          return <NoteContentTag key={element.id} noteContent={element} />;
        })
      }
    </EditableDiv>;
  }
}