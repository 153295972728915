import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import NoteSchema from '../Notes/schema';

export const noteContentSchema = baseSchema(EK.NOTE_CONTENTS.state, {
  note: { schema: NoteSchema, isId: true },
});

export default noteContentSchema;
