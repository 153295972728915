import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllNoteContentsTypes, createNoteContentTypes, saveNoteContentsTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.NOTE_CONTENTS);

export const processFetchAllNoteContents = (noteId) => client(REQUEST_TYPES.GET, 'plural', fetchAllNoteContentsTypes, `/standardize/notes/${noteId}/note_contents`);

export const processCreateNoteContents = (noteId, data) => client(REQUEST_TYPES.POST, 'plural', createNoteContentTypes, `/standardize/notes/${noteId}/note_contents`, {
  data: {
    note_contents: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Note successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create note',
  },
});

export const processUpdateNoteContent = (id, data) => client(REQUEST_TYPES.PATCH, 'plural', saveNoteContentsTypes, `/standardize/note_contents/${id}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});